@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Lobster&family=Antic&family=Hubballi&family=Athiti&family=Playfair+Display+SC&display=swap');

//Page Background

.landingPageBackground {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  // min-height: 100vh;
  min-height: calc(100vh - 65px);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

//End

//Landing Page buttons

.actionButtons {
  border: 2px solid black;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 1px 20px;
  font-size: 14px;
  cursor: pointer;
}

.white {
  border-color: #ffffff;
  color: rgb(255, 255, 255);

  &:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
  }
}

.coolBeans {
  border: 0px solid currentColor;
  border-radius: 3rem;
  color: white;
  font-size: 1rem;
  overflow: hidden;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  &::after {
    background-color: #1890ff;
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }

  &:hover::after {
    transform: translate(0, 0);
  }

  &:hover {
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
    transform: scale(1.05);
    will-change: transform;
  }
}

.title-container {
  display: contents;
  color: white;
}

.landing_page-title {
  // font-family: 'Dancing Script', cursive;
  // font-family: 'Lobster', cursive;
  // font-family: 'Cookie', cursive;
  // font-family: 'Hubballi';
  font-family: 'Athiti';
  // font-family: 'Antic';
  // font-family: 'Playfair Display SC', cursive;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 13px;
  color: black;
  text-align: center;
}
.landing_page-subtitle{
    text-align: center;
    color: black;
    text-align: center;
    font-size:20px;
}
.landing_page-description {
  font-size: 14px;
  text-align: center;
}

//END

//Cards

.pageMain {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 1em;
  flex-direction: column;
}

.cardImg {
  padding: 15px;
  object-fit: cover;
  width: 100%;
  max-height: 240px;
  background-color: azure;
}
.cardHover {
  transition: all 0.5s ease-in-out !important;
}

.cardHover:hover {
  transform: scale(1.1);
}

.cardText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 200px;
}

.heartIcon {
  position: relative;
  left: 229px;
  bottom: 229px;
}

//END

//RegisterScreen

.registerScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0% 2em;
  flex-grow: 1;
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.registerScreenMain {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
}

.registerPageContainer {
  width: 100%;
  padding: 30px;
  margin: auto;
}

//END

//Search Screen

.floatEffect {
  box-shadow: 30px 30px 61px #cacaca, -30px -30px 61px #f6f6f6 !important;
}

.searchCardImg {
  padding: 15px;
  object-fit: cover;
  width: 100%;
  height: 250px;
}
.searchCardHover {
  flex-basis: 100%;
  // transition: all 0.5s ease-in-out !important;
}

.searchCardHover:hover {
  transform: scale(1.05);
}

.input-container {
  display: flex;
  width: 100%;
}

.searchIcon {
  padding: 10px;
  color: black;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border: 0px;
}

.input-field:focus {
  border: 0px;
}

//END

//Posts Page

.searchPostCardThumbnail {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

//END
@media (max-width: 767px) {
  .landing_page-title {
    font-size: 25px;
  }
  .landing_page-subtitle{
    font-size: 18px;
  }
  .landing_page-description {
    font-size: 12px;
  }
}

// Here you can add other styles

a {
  color: #39f;
}

// MATERIAL UI OVERRIDES

.MuiPaper-root {
  border-radius: 11px !important;
}

.MuiFormLabel-asterisk {
  color: #db3131 !important;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.MuiInputLabel-root {
  font-size: 0.8rem !important;
}

//END MATERIAL UI OVERRIDES

//Core UI-Header

.header {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
//end Header

//Core UI-Sidebar
.sidebar {
  box-shadow: 0 4px 14px 0 rgba(0, 217, 255, 0.8);
  background: #000015;
}

.sidebar-nav .nav-link {
  &.active {
    background: #39f;
  }

  &:hover {
    background: #39f;
  }
}
.sidebar-nav .nav-group.show {
  background: #1e2124;
}

//Sidebar Brand TExxt animation
.textanimation {
  font: 700 2em/1 'Oswald', sans-serif;
  letter-spacing: 0;
  padding: 0.25em 0 0.325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);

  /* Clip Background Image */

  background: url(/assets/images/animated-text-fill.png) repeat-y;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

/* Animate Background Image */

@-webkit-keyframes aitf {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
//

//end Sidebar---->

//fade in effect
.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//end

//Ant Design OVERRIDES

.anticon {
  vertical-align: 0 !important;
}

.ant-btn-success {
  color: #fff;
  border-color: #18ff8b;
  background: #18ff8b;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

#ant-btn-warning {
  color: rgb(0, 0, 0);
  border-color: #fff018;
  background: #fff018;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-notification-notice {
  background: antiquewhite !important;
}

.ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 60px !important;
  font-size: 16px;
}

.ant-notification-notice-description {
  margin-left: 60px !important;
  font-size: 14px;
}

.ant-list-item {
  background: #fff;

  &:hover {
    background: aliceblue;
  }
}

//END

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f510;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f90;
}

.chatScreenImg {
  padding: 15px;
  object-fit: contain;
  width: 100%;
  max-height: 240px;
}

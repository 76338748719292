@import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.__profile-cover {
  object-fit: cover;
  width: 100%;
  height: 240px;
  border-radius: 10px;
  background-color: rgb(220, 244, 255);
}

.__profile-actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.__profile-detailsContainer {
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1061px;
  margin: auto;
}

.__profile-DPContainer {
  display: flex;
  justify-content: center;
  max-width: 1110px;
  margin: auto;
}
.__profile-detailsCardContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.__profile-user-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // flex-direction: column;
}

.__profile-boardTitle {
  font-family: 'Akaya Telivigala', cursive, sans-serif;
  font-size: x-large;
}

.__profile-boardDescription {
  font-family: 'Abel', sans-serif;
  text-align: justify;
  // color: white;
}

.__profile-boardThumbnail {
  // padding: 15px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  // background-color: azure;
}

//New Bulletin boards
.signboard {
  width: 400px;
  min-height: 400px;
  position: relative;
}

.sign {
  background: #dedede;
  border-radius: 15px;
  // border: solid 0.5px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.signTitle {
  display: block;
  width: 75%;
  margin: auto;
  font-size: 25px;
  color: saddlebrown;
  font-family: serif;
  font-weight: bold;
  // font-family: 'Akaya Telivigala', cursive, sans-serif;
  text-align: center;
  line-height: 42px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3), 0 -1px 0 rgba(0, 0, 0, 0.7);
}

.pin {
  width: 30px;
  height: 17px;
  // border-radius: 50%;
  position: absolute;
}

.pin.top {
  background: gray;
  left: 187px;
}

.pin.left,
.pin.right {
  top: 10px;
}

.pin.left {
  left: 0px;
}

.pin.right {
  right: 10px;
}

//END

@media (max-width: 767px) {
  .__profile-actionButtons {
    justify-content: center;
  }

  .__profile-detailsContainer {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
  }

  .__profile-detailsCardContainer {
  }
  .__profile-user-name {
    justify-content: center;
  }
}
